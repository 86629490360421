"use strict";

var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

/*
function getCache(cacheName) {
    var isCacheable = 5;
    var result = null;
    if (isCacheable > 0) {
        var storageName = "marideal-pwa_" + cacheName;
        result = JSON.parse(localStorage.getItem(storageName) || null);
    }
    return result;
}

function getPlatform_() {
    return getCache('platform') ? (getCache('platform').data ? getCache('platform').data : '') : '';
}

var plf = getPlatform_();
console.log(plf);
if(plf == "iOS"){
    console.log('iOS*********');
    var sc = `<!-- Global site tag (gtag.js) - GA4 --><script async src="https://www.googletagmanager.com/gtag/js?id=G-RYK31WW09K"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-RYK31WW09K');</script>`;
    $("head").append(sc);
}
else if(plf == "android"){
    console.log('android*********');
    var sc = `<!-- Global site tag (gtag.js) - GA4 --><script async src="https://www.googletagmanager.com/gtag/js?id=G-B7QSVZY9P7"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-B7QSVZY9P7');</script>`;
    $("head").append(sc);
}
else if(plf == "huawei"){
    console.log('huawei*********');
    var sc = `<!-- Global site tag (gtag.js) - GA4 --><script async src="https://www.googletagmanager.com/gtag/js?id=G-7G6HLLKJE6"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-7G6HLLKJE6');</script>`;
    $("head").append(sc);
}
else{
    console.log('pwa*********');
    var sc = `<!-- Global site tag (gtag.js) - GA4 --><script async src="https://www.googletagmanager.com/gtag/js?id=G-6JVWRV7EP9"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-6JVWRV7EP9');</script>`;
    $("head").append(sc);
}

*/

if (ios) {
    if (!standalone && safari) {
        //var gaPlatformKey = "UA-46203988-4"; //pwa
      //  var gaPlatformKey = "G-6JVWRV7EP9"; //pwa
        var ptKey="G-549LK59T7Y"
        var gtmKey = "GTM-5B5XT68";
    } else if (!standalone && !safari) {
        //var gaPlatformKey = "UA-46203988-5"; //iOS wrapper app
       // var gaPlatformKey = "G-RYK31WW09K"; //iOS wrapper app
        var ptKey="G-MNJXDH5NZF"
        var gtmKey = "GTM-NGHMB3F";

    }
} else {
    if (userAgent.includes("huawei")) {
        //var gaPlatformKey = "UA-46203988-7"; //huawei
       // var gaPlatformKey = "G-7G6HLLKJE6"; //huawei
       var ptKey="G-HBPP88F2CD";
       var gtmKey = "GTM-M4T9R6X";


    } else if (userAgent.includes("wv")) {
        //var gaPlatformKey = "UA-46203988-6"; //Android Wrapper
      //  var gaPlatformKey = "G-B7QSVZY9P7"; //Android Wrapper
        var ptKey="G-BHE59523F1"
        var gtmKey = "GTM-NP52VDK";


    } else {
        //var gaPlatformKey = "UA-46203988-4"; //pwa
        //var gaPlatformKey = "G-6JVWRV7EP9"; //pwa
        var ptKey="G-549LK59T7Y"
        var gtmKey = "GTM-5B5XT68";

    }
}

//Comment this part later
/*
var jsfile0 = `<script>(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=0;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '` + gaPlatformKey + `', 'auto');ga('require', 'displayfeatures');ga('require', 'ecommerce', 'ecommerce.js');</script>`;
$("head").append(jsfile0);
*/
//Comment this part later

// var jsfile = `<!-- Global site tag (gtag.js) - GA4 --><script async src="https://www.googletagmanager.com/gtag/js?id=`+gaPlatformKey+`"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '`+gaPlatformKey+`');</script>`;

// $("head").append(jsfile);

var jsfile4= `<!-- Google tag (gtag.js) --> <script async src="https://www.googletagmanager.com/gtag/js?id=`+ptKey+`"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '`+ptKey+`'); </script>`;

$("head").append(jsfile4);

var jsfile2 = `<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','`+gtmKey+`');</script>
<!-- End Google Tag Manager -->`

$("head").append(jsfile2);

var jsfile3 = `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=`+gtmKey+`"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`;

$("body").prepend(jsfile3);

function getPlatform(platform) {
    var date = new Date();
    var data = JSON.stringify({time: date.getTime(), data: platform});
    localStorage.setItem('marideal-pwa_platform', data);
    console.log(platform);
}

function getAndroidDeviceOS(androidOs) {
    var date = new Date();
    var data = JSON.stringify({time: date.getTime(), data: androidOs});
    localStorage.setItem('marideal-pwa_androidOs', data);
    console.log(androidOs)
}

function setNotificationTime(NotTime) {
    var date = new Date();
    var data = JSON.stringify({time: date.getTime(), data: NotTime});
    localStorage.setItem('marideal-pwa_notificationTime', data);
    console.log('NotTime=' + NotTime);
}

function loginWithSocialInfo(userInfo) {
    window.hadleSocialLogin(userInfo);
}